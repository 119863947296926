import {Box, Chip, Popover, Skeleton, Typography} from '@mui/material'

import { useEffect, useState } from 'react'
import moment from "moment";

const StatusInvoice = (props) => {
  const { invoice } = props

  const [status, setStatus] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (invoice?.status) {
      setStatus({
        color: invoice?.status?.key === 'paid' ? 'success' :
          invoice?.status?.key === 'pending' ? 'warning' :
          invoice?.status?.key === 'exemption' ? 'secondary' :
          invoice?.status?.key === 'overdue' ? 'error' :
          invoice?.status?.key === 'open' && 'info',
        label: invoice?.status?.value
      })
    }

  }, [invoice?.status])

  return (
    <>
      {status ?
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContet: 'center',
            flexDirection: 'column'
        }}
        >
          <Chip
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            color={status?.color}
            size="small"
            variant="outlined"
            label={status?.label}/>
          {invoice?.status?.key === "paid" &&
            <Popover
              id="paid-at-popover"
              sx={{
                pointerEvents: 'none',
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography fontSize={'small'} sx={{ p: 1 }}>{'Pago em '}{moment(invoice?.paid_at).format('DD/MM/YYYY')}</Typography>
            </Popover>
          }
          <Typography
            sx={{
              mt: '0.3rem',
              fontSize: '0.7rem'
            }}
          >
            {invoice?.paid_manually && 'Baixa Manual'}
          </Typography>
        </Box>
        : <Skeleton variant="text" animation="pulse" width={100} height={20}/>}
    </>
  )
}

export default StatusInvoice