import DialogTitle from "@mui/material/DialogTitle";
import {Button, Dialog, Divider, FormControlLabel, Switch, TextField} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import moment from "moment/moment";
import DialogActions from "@mui/material/DialogActions";
import * as React from "react";
import {useMediaQuery} from "@mui/material";

const MakeDuplicate = ({ openByStatus, reqNewInvoice, setReqNewInvoice, handleCloseConfirmation, generateNew, errors }) => {


  const sizeMatch = useMediaQuery('@media (min-width:600px)')

  return (
    <Dialog
      fullScreen={!sizeMatch}
      sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '600px' } }}
      maxWidth="xs"
      open={openByStatus.remake  ?? !!openByStatus.remake}
      onClick={event => event.stopPropagation()}
      sx={{
        '& .MuiDialog-paper': { width: '80%', maxHeight: 435, padding: 2  }
      }}
    >
      <DialogTitle>
        {"Segunda Via"}
      </DialogTitle>

      <Divider />

      <DialogContent >
        {"Para onde deseja enviar a segunda via?"}
      </DialogContent>

      <FormControlLabel
        label={"Enviar por e-mail?"}
        sx={{
          pl: 2,
          pr: 2
        }}
        control={
          <Switch
            checked={reqNewInvoice.send_email}
            onChange={(e) => {
              setReqNewInvoice((prevState) => ({...prevState, send_email: !reqNewInvoice.send_email}))
            }}
            name="Enviar por e-mail"/>}
      />
      {/*<FormControlLabel*/}
      {/*  sx={{pl: 2, pr: 2}}*/}
      {/*  control={*/}
      {/*    <Switch*/}
      {/*      checked={reqNewInvoice.send_whatsapp}*/}
      {/*      onChange={(e) => {*/}
      {/*        setReqNewInvoice((prevState) => ({...prevState , send_whatsapp: !reqNewInvoice.send_whatsapp}))*/}
      {/*      }}*/}
      {/*      name="Enviar por whatsapp"*/}
      {/*    />}*/}
      {/*  label={"Enviar por Whatsapp?"}*/}
      {/*/>*/}
      <Divider
        sx={{
          marginY: '1rem'
        }}
      />

      <DesktopDatePicker
        label="Nova Data de Vencimento"
        value={new Date(moment(reqNewInvoice.due_date))}
        minDate={new Date(moment().format())}
        disableHighlightToday
        onChange={(e) => {
          setReqNewInvoice((prevState) => ({...prevState,due_date: moment(e).format('YYYY-MM-DD')}))
        }}
        renderInput={(params) => <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            placeholder:'dd/mm/aaaa'
          }}
          fullWidth
          error={!!errors}
        />}
      />

      <DialogActions
        sx={{mt: 2}}
      >
        <Button autoFocus onClick={handleCloseConfirmation} >{"Cancelar"}</Button>
        <Button variant={"contained"} onClick={generateNew}>{"Confirmar"}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default MakeDuplicate