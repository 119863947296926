import React, { useEffect, useState, useContext } from 'react'
import {
  Card,
  CardContent,
  Container,
  Stack, TextField
} from '@mui/material'
import Context from '../../contexts/Context'
import CardInvoiceInfo from '../../components/Invoice/CardInvoiceInfo'
import {getCondoId, getFilterDate, setFilterDate} from '../../services/Main/AuthStorage'
import { Helmet } from 'react-helmet'
import Treemap from '../../components/Charts/Treemap'
import { Box } from '@mui/system'
import Pizza from '../../components/Charts/Pizza'
import OverduesCard from "../../components/Shared/OverduesCard";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";


const Dashboard = () => {

  const storageDate = getFilterDate()

  const { condoIdLogin } = useContext(Context)
  const [date, setDate] = useState(storageDate ? moment(new Date(storageDate)).format() : moment().format())
  const condo = getCondoId()

  useEffect(function preventError() {
    setFilterDate(date)
  }, [])

  return (
    <>
      <Helmet>
        <title>Início - Bloco B3</title>
      </Helmet>
      {(condoIdLogin || condo) &&
        <Container maxWidth="100%">
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Stack justifyContent="space-between" direction="row" spacing={1}>
                <DatePicker
                    label="Filtrar Por Mês/Ano"
                    openTo="month"
                    views={['year', 'month',]}
                    value={date}
                    onChange={(newValue) => {
                      setDate(moment(new Date(newValue)).locale('pt-br').format())
                      setFilterDate(moment(new Date(newValue)).locale('pt-br').format())

                    }}
                    renderInput={(params) =>
                        <TextField
                            size="small"
                            {...params}
                        />}
                />
              </Stack>
          </Box>
          <CardInvoiceInfo date={storageDate}/>
          <Stack
            justifyContent="space-between"
            direction="row"
            sx={{
              justifySelf: 'center',
              marginY: '2rem',
              '@media (max-width:1350px)': {
                display: 'grid',
                gridTemplateColumns: 'repeat(1, 1fr)',
                gap: 3,
              },
            }}>
                <Pizza date={date} />

                <OverduesCard />
          </Stack>
          <Card sx={{ width: '100%' }}>
            <CardContent>
              <Treemap date={date}/>
            </CardContent>
          </Card>

        </Container>
      }
    </>
  )
}

export default Dashboard
