import { useCallback, useContext, useEffect, useState } from 'react'
import Context from '../../../contexts/Context'
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Fade,
  FormControlLabel,
  Grid, IconButton, InputAdornment,
  Link,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import {LockClockOutlined, Visibility, VisibilityOff} from '@mui/icons-material'
import logo from '../../../assets/images/blocob3-logo.png'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {getToken} from "../../../services/Main/AuthToken";
import {removeTokensUnitUser} from "../../../services/UnitUser/UnitUserToken";
import ReCAPTCHA from "react-google-recaptcha";
import {useSnackbar} from "notistack";

const Login = () => {
  const { enqueueSnackbar } = useSnackbar()


  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { signIn, user, errors, setErrors } = useContext(Context)
  const navigate = useNavigate()
  const [token, setToken] = useState(null)
  const [showPassword, setShowPassword] = useState (false)
  const [captchaValue, setCaptchaValue] = useState(null);



  useEffect(() => {
    if (user && getToken()) {
      navigate('/', { replace: true })
    }
  }, [user])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setErrors(null)


    const data = {
      email,
      password
    }
    if (captchaValue) {
      await signIn(data)
      setIsLoading(false)
    } else {
      enqueueSnackbar('Por favor realize o CAPTCHA', { variant: 'error' })
      setIsLoading(false)
    }
  }

  const handleCaptcha = useCallback((e) => {
    setToken(e)
  }, [])

  const handleChange = ({ target: { name, value } }) => {
    setErrors(null)
    if (name === 'email') {
      setEmail(value)
    }
    if (name === 'password') {
      setPassword(value)
    }
  }

  return (
    <>
      <Helmet>
        <title>Login - Bloco B3</title>
      </Helmet>
      {/*<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_API_KEY} language={"pt-BR"}>*/}
        <Container component="main" maxWidth="xs">
          <CssBaseline/>
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar variant={'square'} sx={{ m: 1, height: 64, width: 64 }} src={logo}>
              <LockClockOutlined fontSize={'large'}/>
            </Avatar>
            <Typography component="h1" variant="h5">{'Bem Vindo!'}</Typography>


            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1}}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  maxWidth: '300px'
                }}
              >
              {errors?.detail &&
                <Fade in={!!errors.detail}>
                  <Alert variant={'filled'} severity={'error'} sx={{ width: '100%', mt: 3 }}>
                    {'Usuário e/ou senha inválidos'}
                  </Alert>
                </Fade>
              }
              <TextField
                margin="normal"
                size={'small'}
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
                error={errors?.email}
                helperText={errors?.email ? errors.email[0] : ''}
              />

              <TextField
                margin="normal"
                size={'small'}
                required
                fullWidth
                name="password"
                label="Senha"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
                error={errors?.password}
                helperText={errors?.password ? errors.password[0] : ''}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(prevState => !prevState)}
                        edge="end"
                      >
                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                      </IconButton>
                    </InputAdornment>,
                }}
                sx={{
                  mb: '2rem'
                }}
              />
              <ReCAPTCHA
                sitekey="6Lc7yJYpAAAAAEuJpedcLy1RVoqz8LMldiEY7wJz"
                onChange={(value) => setCaptchaValue(value)}
                hl="pt-BR"
              />
              </Box>


              {/*<GoogleReCaptcha onVerify={handleCaptcha}/>*/}
              {/*<FormControlLabel control={<Switch/>} label={'Lembrar'}/>*/}
              <Button
                size={'small'}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress color={'primary'} size={28}/> : 'Entrar'}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/recuperar-senha" variant="body2">{'Esqueceu a Senha?'}</Link>
                </Grid>
                <Grid item>
                  <Link href="/login/usuario" variant="body2">{'Usuário?'}</Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      {/*</GoogleReCaptchaProvider>*/}
    </>
  )
}

export default Login
