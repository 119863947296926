import {ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import {FileCopy, TaskAltOutlined} from "@mui/icons-material";
import * as React from "react";
import {useContext, useRef, useState} from "react";
import {api} from "../../../../../services/Main/Api";
import SimpleDialog from "../../../../../components/Shared/SimpleDialog";
import {useSnackbar} from "notistack";
import Context from "../../../../../contexts/Context";

const MakeMenu = ({ invoice, isOpen, anchor, setOpenByStatus, openByStatus, setIsOpen }) => {
  const { setFilters, setLoadingModal } = useContext(Context)
  const [openDelete, setOpenDelete] = useState(false)
  const {enqueueSnackbar} = useSnackbar()


  function deleteSlip () {
    setLoadingModal(true)

    api.delete(`bank-slip/invoice/${invoice?.id}/`)
      .then(() => {
        enqueueSnackbar("Boleto cancelado!", {variant: 'success'})
        setOpenDelete(false)
        setFilters(prevState => ({...prevState, refreshList: !prevState}))
      })
      .catch(error => {
        console.log(error)
        enqueueSnackbar("Erro ao cancelar boleto", {variant: "error"})
      })
      .finally(() => {
        setLoadingModal(false)
      })
  }

  return (
    <>
    <Menu
      open={isOpen ??!!isOpen}
      anchorEl={anchor && anchor}
      onClose={(e) => {
        e.stopPropagation()
        setIsOpen(false)
      }}
      PaperProps={{
        sx: { width: 200, maxWidth: '100%' },
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      {invoice?.status?.key === 'open' ?
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            setOpenByStatus((prevState) => ({...prevState, create: !openByStatus.create}))

          }}
          sx={{
            color: 'text.secondary'
          }}
        >
          <ListItemIcon>
            <TaskAltOutlined/>
          </ListItemIcon>
          <ListItemText
            primary="Gerar Boleto"
            primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>
        : invoice?.status?.key !== "paid" &&
        <MenuItem
          onClick={(e) => {
          e.stopPropagation()
          setOpenDelete(true)
         }}
          // disabled={invoice?.status?.key !== "open"}
          sx={{
            color: 'text.secondary'
          }}
        >
          <ListItemIcon>
            <TaskAltOutlined/>
          </ListItemIcon>
          <ListItemText
          primary="Cancelar Boleto"
          primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>
      }


      {!(invoice?.status?.key === "paid") &&
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            setOpenByStatus((prevState) => ({...prevState, paid: !openByStatus.paid}))
          }}
          sx={{color: 'text.secondary'}}
        >
          <ListItemIcon>
            <TaskAltOutlined/>
          </ListItemIcon>
          <ListItemText primary="Baixa Manual" primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>
      }
      {!(invoice?.status?.key !== 'overdue' && invoice?.status?.key !== 'pending') &&
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            setOpenByStatus((prevState) => ({...prevState, remake: !openByStatus.remake}))
          }}
          sx={{color: 'text.secondary'}}
        >
          <ListItemIcon>
            <FileCopy/>
          </ListItemIcon>
          <ListItemText primary="Segunda Via" primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>
      }
    </Menu>
      <SimpleDialog
        title={'Cancelar boleto'}
        open={openDelete}
        setOpen={setOpenDelete}
        onClickCancel={(e) => e.stopPropagation()}
        onClickConfirm={(e) => {
          e.stopPropagation()
          deleteSlip()
        }}
        question={"Realmente deseja cancelar esse boleto?"}
      />

    </>

  )
}

export default MakeMenu