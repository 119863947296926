import DialogTitle from "@mui/material/DialogTitle";
import {Button, Dialog, Divider, FormControlLabel, Switch, TextField} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import moment from "moment";
import DialogActions from "@mui/material/DialogActions";
import * as React from "react";
import {useMediaQuery} from "@mui/material";


const MakeBankSlip = ({ openByStatus, setOpenByStatus, reqCreateInvoice, setReqCreateInvoice, handleCloseConfirmation, generate, errors}) => {
  const sizeMatch = useMediaQuery('@media (min-width:600px)')


  return (
    <Dialog
      fullScreen={!sizeMatch}
      sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '600px', maxHeight: 435, padding: 2 } }}
      // sx={{
      //   '& .MuiDialog-paper': { width: '80%', maxHeight: 435, padding: 2  }
      // }}
      maxWidth="xs"
      open={openByStatus.create ?? !!openByStatus.create}
      onClick={event => event.stopPropagation()}
    >
      <DialogTitle >{"Gerar Boleto"}</DialogTitle>
      <Divider />
      <DialogContent >
        {"Para onde deseja enviar o boleto?"}
      </DialogContent>
      <FormControlLabel
        label={"Enviar por e-mail?"}
        sx={{
          pl: 2,
          pr: 2
        }}
        control={

          <Switch
            checked={reqCreateInvoice.send_email}
            onChange={(e) => {
              setReqCreateInvoice((prevState) => ({...prevState, send_email: !reqCreateInvoice.send_email}))
            }}
            name="Enviar por e-mail"/>}
      />
      {/*<FormControlLabel*/}
      {/*  sx={{pl: 2, pr: 2}}*/}
      {/*  control={*/}
      {/*    <Switch*/}
      {/*      checked={reqCreateInvoice.send_whatsapp}*/}
      {/*      onChange={(e) => {*/}
      {/*        setReqCreateInvoice((prevState) => ({...prevState , send_whatsapp: !reqCreateInvoice.send_whatsapp}))*/}
      {/*      }}*/}
      {/*      name="Enviar por whatsapp"/>}*/}
      {/*  label={"Enviar por Whatsapp?"}*/}
      {/*/>*/}
      <Divider sx={{ marginY: '1rem' }}/>

      <DesktopDatePicker
        label="Data de Vencimento"
        value={moment(reqCreateInvoice.due_date).format()}
        minDate={new Date(moment().format())}
        disableHighlightToday
        onChange={(e) => {
          setReqCreateInvoice((prevState) => ({...prevState,due_date: moment(e).format('YYYY-MM-DD')}))
        }}
        renderInput={(params) => <TextField
          {...params}
          fullWidth
          inputProps={{
            ...params.inputProps,
            placeholder:'dd/mm/aaaa'
          }}
          error={!!errors}
        />}
      />

      <DialogActions
        sx={{mt: 2}}
      >
        <Button autoFocus onClick={handleCloseConfirmation} >{"Cancelar"}</Button>
        <Button variant={"contained"} onClick={(e) => {
          e.stopPropagation()
          setOpenByStatus(prevState => ({...prevState, create: false}))
          generate()
        }}>{"Confirmar"}</Button>
      </DialogActions>

    </Dialog>
  )
}

export default MakeBankSlip