import {TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import React, {useContext} from "react";
import Context from "../../../../../contexts/Context";

const TableInvoiceHead = () => {
  const {filters, setFilters} = useContext(Context)


  const handleFilter = (asc: string, desc: string) => {
    setFilters(prevState => ({
        ...prevState,
        ordering: filters?.ordering === asc ? desc : asc
      })
    )
  }


  return (
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              color: 'primary.main'
            }}
          >
            <TableSortLabel
              style={{ color: '#DD1960' }}
              active={filters?.ordering === '-number' || filters?.ordering === 'number'}
              direction={filters?.ordering === '-number' ? 'desc' : 'asc'}
              onClick={() => handleFilter('number', '-number') }
            >
            Nº da Fatura
            </TableSortLabel>
          </TableCell>
          <TableCell
            sx={{
              color: 'primary.main'
            }}
          >
            <TableSortLabel
              style={{ color: '#DD1960' }}
              active={filters?.ordering === '-unit' || filters?.ordering === 'unit'}
              direction={filters?.ordering === '-unit' ? 'desc' : 'asc'}
              onClick={() => handleFilter('unit', '-unit') }
            >
            Imóvel
            </TableSortLabel>
          </TableCell>
          <TableCell
            align={'center'}
            sx={{
              color: 'primary.main'
            }}
          >
            <TableSortLabel
              style={{ color: '#DD1960' }}
              active={filters?.ordering === '-reference_month' || filters?.ordering === 'reference_month'}
              direction={filters?.ordering === '-reference_month' ? 'desc' : 'asc'}
              onClick={() => handleFilter('reference_month', '-reference_month') }
            >
              Referência
            </TableSortLabel>
          </TableCell>
          <TableCell
            align={'center'}
            sx={{
              color: 'primary.main'
            }}
          >
            <TableSortLabel
              style={{ color: '#DD1960' }}
              active={filters?.ordering === '-due_date' || filters?.ordering === 'due_date'}
              direction={filters?.ordering === '-due_date' ? 'desc' : 'asc'}
              onClick={() => handleFilter('due_date', '-due_date') }
            >
              Vencimento
            </TableSortLabel>
          </TableCell>
          <TableCell
            align={'center'}
            sx={{
              color: 'primary.main'
            }}
          >
            <TableSortLabel
              style={{ color: '#DD1960' }}
              active={filters?.ordering === '-paid_at' || filters?.ordering === 'paid_at'}
              direction={filters?.ordering === '-paid_at' ? 'desc' : 'asc'}
              onClick={() => handleFilter('paid_at', '-paid_at') }
            >
              Situação
            </TableSortLabel>
          </TableCell>
          <TableCell
            sx={{
              color: 'primary.main'
            }}
          >
            <TableSortLabel
              style={{ color: '#DD1960' }}
              active={filters?.ordering === '-total' || filters?.ordering === 'total'}
              direction={filters?.ordering === '-total' ? 'desc' : 'asc'}
              onClick={() => handleFilter('total', '-total') }
            >
              Total
            </TableSortLabel>
          </TableCell>
          <TableCell
            align={'center'}
            sx={{
              color: 'primary.main',
            }}
          >
            <TableSortLabel
              style={{ color: '#DD1960' }}
              active={filters?.ordering === '-bank_slip' || filters?.ordering === 'bank_slip'}
              direction={filters?.ordering === '-bank_slip' ? 'desc' : 'asc'}
              onClick={() => handleFilter('bank_slip', '-bank_slip') }
            >
             Boleto
            </TableSortLabel>
          </TableCell>
          <TableCell/>
        </TableRow>
      </TableHead>
  )
}

export default TableInvoiceHead