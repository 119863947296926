import moment from 'moment/moment'
import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import React, {useEffect, useState} from 'react'
import {getFilterDate, setFilterDate} from "../../services/Main/AuthStorage"

const ReferenceDate = ({ setFilters }) => {
  const storageDate = getFilterDate()

  const [dateValue, setDateValue] = useState(moment(new Date(storageDate)).format() ?? moment().format())

  const monthFormat = +moment(storageDate).format("MM")
  const yearFormat = +moment(storageDate).format("YYYY")

  useEffect(() => {
    if (dateValue) {
      setFilters((prevState) => ({
        ...prevState,
        billing_month: monthFormat,
        billing_year: yearFormat
      }))
    }
  }, [dateValue] )

  return (
    <>
      <DatePicker
        label="Data de Referência"
        openTo="month"
        views={['year', 'month',]}
        value={storageDate}
        clearLabel
        onChange={(newValue) => {
          setDateValue(moment(new Date(newValue)).format())
          setFilterDate(moment(new Date(newValue)).format())
        }}
        renderInput={(params) =>
            <TextField
                size="small"
                {...params}
            />}
      />
    </>
  )
}
export default ReferenceDate