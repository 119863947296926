import React, {useEffect, useState, useRef} from "react";
import {
  Box,
  Skeleton,
  Link, Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography, Card, CardContent
} from "@mui/material";
import InvoiceIcon from "../Invoice/InvoiceIcon";
import moment from "moment/moment";
import StatusInvoice from "../Invoice/StatusInvoice";
import NumberFormatB3 from "../NumberFormat/NumberFormat";
import SlipStatus from "../Invoice/SlipStatus";
import {api} from "../../services/Main/Api";
import {useNavigate} from "react-router-dom";
import lottie from  'lottie-web'
import {grey} from "@mui/material/colors";
import {getCondoId} from "../../services/Main/AuthStorage";
import Lottie from "lottie-react";
import congratulation from "../../assets/animations/congratulation.json";
import AnimationGlobal from "./animations/AnimationGlobal";

const OverduesCard = () => {
  const [overdues, setOverdues] = useState([])
  const navigate = useNavigate()
  const container = useRef(null)
  const [isLoading, setIsLoading] = useState(false)


  useEffect(function whenLoad() {
    const condo = getCondoId()

    setIsLoading(true)
    api.get(`/invoice/?unit__condo_id=${condo}&status=overdue&page_size=200`)
      .then(response => {
        setOverdues(response?.data?.results)
        setIsLoading(false)
      }).catch(error => {
      setIsLoading(false)
      console.log(error)
    })

  }, [])


  return (
    <Card sx={{

      width: '100%',
      maxHeight: '600px',
      overflow: 'auto'}}
    >
      {isLoading  ?
        <Skeleton
          height={'500px'}
          variant={"rounded"}
          animation={'pulse'}

        /> : overdues?.length > 0 ?
      <CardContent>
        <Typography
          sx={{
            fontFamily: 'Helvetica, Arial, sans-serif',
            textAnchor: 'start',
            dominantBaseline: 'auto',
            fontSize: '14px',
            fontWeight: 900,
            mb: '2rem'
          }}
          >
          Inadimplências
        </Typography>

          <>
        <TableContainer


        >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: 'primary.main' }}>
                    Nº da Fatura
                  </TableCell>
                  <TableCell sx={{ color: 'primary.main' }}>
                    Imóvel
                  </TableCell>
                  <TableCell align={'center'} sx={{ color: 'primary.main' }}>
                    Vencimento
                  </TableCell>
                  <TableCell sx={{ color: 'primary.main' }}>
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
              >
                {overdues?.map(object =>
                  <TableRow
                    key={object.number}
                    hover onClick={() => { navigate(`/faturas/${object.id}`)}}
                    sx={{ 'cursor': 'pointer', }}
                  >
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <InvoiceIcon variant={object?.status.key} key={object.id}/>
                        {object.number}
                      </Box>
                    </TableCell>
                    <TableCell>{object.unit}</TableCell>
                    <TableCell align={'center'}>
                      {object.due_date
                        ? moment(object.due_date).format('DD/MM/YYYY')
                        : '-'}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      <NumberFormatB3 value={object.total}/>
                    </TableCell>
                  </TableRow>
                )
                }
              </TableBody>
            </Table>
        </TableContainer>
      </>
      </CardContent>

          :

            <AnimationGlobal
              animation={congratulation}
              text={'Sem Inadimplências!'}
              size={'800px'}
              fontSize={'24px'}

            />

      }
  </Card>
  )
}

export default OverduesCard;
