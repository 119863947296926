import {Alert, Box, Chip, Divider, FormControlLabel, FormLabel, Switch, TextField} from "@mui/material";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import moment from "moment/moment";
import React from "react";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {getFilterDate} from "../../../../../services/Main/AuthStorage";

const GenerateCharge = ({errors, setErrors, chargeReq, setChargeReq}) => {

  return(
    <Box>
      {errors?.condo ?
        <Box
          sx={{
            mb: 2
          }}
        >
          <Alert
            variant={'filled'}
            severity={'warning'}
          >
            {errors?.condo}
          </Alert>
        </Box>
        : ''
      }
      <DesktopDatePicker
        label="Data de Vencimento"
        value={moment(chargeReq?.due_date).format()}
        minDate={moment().add(1, 'days')}
        disableHighlightToday
        onChange={(e) => {
          setChargeReq((prevState) => ({...prevState, due_date: moment.utc(new Date(e)).format('YYYY-MM-DD')}))
          setErrors((prevState) => ({ prevState, due_date: null }))
        }}
        renderInput={(params) => <TextField
          {...params}
          fullWidth
          error={!!errors?.due_date}
          helperText={errors?.due_date ? errors.due_date : ''}
        />}
      />
      <Divider sx={{ marginY: '1rem' }}/>
      <DesktopDatePicker
        label="Mês de Pagamento"
        disableMaskedInput
        inputFormat="MMMM"
        value={chargeReq?.month}
        openTo={'month'}
        views={['month']}
        disableHighlightToday
        onChange={(e) => {
          setChargeReq((prevState) => ({...prevState, month: moment(e).format()}))
          setErrors((prevState) => ({ ...prevState, month: null }))
        }}
        renderInput={(params) => <TextField
          {...params}
          fullWidth
          error={!!errors?.month}
          helperText={errors?.month ? errors.month : ''}

        />}
      />
      <Divider
        sx={{
          marginY: '1rem'
        }}
      />
      <DesktopDatePicker
        label="Ano de Referência"
        disableMaskedInput
        inputFormat="yyyy"
        value={moment(chargeReq?.year).format()}
        views={['year']}
        openTo={'year'}
        disableHighlightToday
        onChange={(e) => {
          setChargeReq((prevState) => ({ ...prevState, year: moment.utc(e).format('yyyy') }))
          setErrors((prevState) => ({ ...prevState, year: null }))
        }}
        renderInput={(params) =>
        <TextField
          {...params}
          fullWidth
          error={!!errors?.month}
          helperText={errors?.month ? errors.month : ''}
        />}
      />
      <Divider
        sx={{
          marginY: '1rem'
        }}
      />
      <FormLabel
        component="legend"
      >
        E-mail
      </FormLabel>
      <FormControlLabel
        control={
          <Switch
            value={chargeReq?.send_email}
            // checked={chargeReq?.send_email}
            onChange={(e) => {
             setChargeReq((prevState) => ({...prevState, send_email: e.target.checked}))}
            }
            name="Enviar e-mail"
          />
        }
        label="Enviar via E-mail"
      />
      <Divider
        sx={{
          marginY: '1rem'
        }}
      />
      <FormLabel
        component="legend"
      >
        {'WhatsApp '}
        <Chip
          color="info"
          size="small"
          variant="outlined"
          label=" Em breve"
        >
        </Chip>
      </FormLabel>
      <FormControlLabel
        disabled
        control={
          <Switch
            checked={chargeReq?.send_whatsapp}
            onChange={(e) => {
              setChargeReq((prevState) => ({...prevState, send_whatsapp: !chargeReq?.send_whatsapp}))}
            }
            name="whatsapp"/>
        }
        label="Enviar via Whatsapp"
      />
    </Box>
  )
}

export default GenerateCharge