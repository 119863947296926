import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import { LoadingButton } from '@mui/lab'
import { useState } from 'react'
import {useMediaQuery} from "@mui/material";

const ModalForm = (props) => {
  const { onClose, callback, title, open, children, errors, ...other } = props
  const [isLoading, setIsLoading] = useState(false)
  const sizeMatch = useMediaQuery('@media (min-width:600px)')


  const handleCancel = () => {
    onClose()
  }

  const handleOk = () => {
    setIsLoading(true)
    callback()
    setIsLoading(false)
  }

  return (
    <Dialog
      fullScreen={!sizeMatch}
      sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '600px' } }}
      open={open}
      onClose={onClose}
      {...other}
    >
      <DialogTitle>{title || 'Bloco B3'}</DialogTitle>
      <DialogContent dividers>
        {children}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>{'Cancelar'}</Button>
        <LoadingButton
          loading={isLoading}
          variant={'contained'}
          onClick={handleOk}>
          {'Gerar Cobrança'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

ModalForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  errors: PropTypes.object
}

ModalForm.defaultProps = {
  errors: null
}

export default ModalForm
