import React from "react";
import {
  Box,
  IconButton,
  Link,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import InvoiceIcon from "../../../../../components/Invoice/InvoiceIcon";
import moment from "moment/moment";
import StatusInvoice from "../../../../../components/Invoice/StatusInvoice";
import Masks from "../../../../../components/Shared/Masks";
import {Close, PictureAsPdf} from "@mui/icons-material";
import MakeItPaidMenu from "../../MakeItPaidMenu";
import {useNavigate} from "react-router-dom";

const TableInvoiceBody = ({state, handleManualPayment}) => {
  const navigate = useNavigate()

  return (
    <TableBody>
      {state?.results?.map(object =>
        <TableRow
          key={object.number}
          hover
          onClick={(e) => {
            e.stopPropagation()
            navigate(`/faturas/${object.id}`)
          }}
          sx={{ 'cursor': 'pointer', }}
        >
          <TableCell>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <InvoiceIcon
                variant={object?.status.key}
                key={object.id}
              />
              {object.number}
            </Box>
          </TableCell>
          <TableCell>
            {object.unit}
          </TableCell>
          <TableCell
            align={'center'}
          >
            {object.reference_month}
          </TableCell>
          <TableCell
            align={'center'}
          >
            {object.due_date
              ? moment(object.due_date).format('DD/MM/YYYY') : '-'
            }
          </TableCell>
          <TableCell
            align={'center'}
          >
            <StatusInvoice
              invoice={object}
              paidAt={object?.paid_at}
            />
          </TableCell>
          <TableCell
            sx={{
              fontWeight: 'bold'
            }}
          >
            {object?.total.toString().includes('-') && '- '}
            {Masks.money(object?.total.toString())}
          </TableCell>
          <TableCell
            align={'center'}
          >
            {object?.bank_slip.length > 0 ?
               <Link
                href={object?.bank_slip[0].file}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                <IconButton>
                  <PictureAsPdf/>
                </IconButton>
              </Link>
              :
              <Close sx={{ color: 'error.main'}} />
            }
          </TableCell>
          <TableCell
            align={'right'}
          >
            {object.status.key !== 'paid' &&
              <MakeItPaidMenu
                invoice={object}
                onSuccess={handleManualPayment}
              />
            }
          </TableCell>
        </TableRow>
      )
      }
    </TableBody>
  )
}

export default TableInvoiceBody;