import React, {
  useContext,
  useEffect,
  useState
} from 'react'
import {
  Backdrop, Box, Button,
  Card,
  Container, Divider,
  Grid,
  Skeleton,
  Table,
  TableContainer, TextField, Typography,
} from '@mui/material'
import { api } from '../../../services/Main/Api'
import {
  QrCode,
  Receipt,
} from '@mui/icons-material'
import CardInvoiceInfo from '../../../components/Invoice/CardInvoiceInfo'
import {
  getCondoId, getCondoType,
  getFilterDate,
  setFilterDate
} from '../../../services/Main/AuthStorage'
import { useSnackbar } from 'notistack'
import ModalForm from '../../../components/Modal/ModalForm'
import moment from 'moment'
import UnitFilter from '../../../components/Filters/UnitFilter'
import ReferenceMonthFilter from '../../../components/Filters/ReferenceMonthFilter'
import StatusTabFilter from '../../../components/Filters/StatusTabsFilter'
import { Helmet } from 'react-helmet'
import CircularProgress from '@mui/material/CircularProgress'
import Context from '../../../contexts/Context'
import TableInvoiceHead from "./components/InvoiceList/TableInvoiceHead";
import TableInvoiceBody from "./components/InvoiceList/TableInvoiceBody";
import NoResultsAnimation from "../../../components/Shared/animations/NoResultsAnimation";
import GenerateCharge from "./components/InvoiceList/GenerateCharge";
import TitleBtnList from "../../../components/Shared/TitleBtnList";
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";
import { DatePicker } from '@mui/x-date-pickers/DatePicker'


const InvoiceList = () => {
  const [response, setResponse] = useState(null)
  const [chargeReq, setChargeReq] = useState(
    {
      condo: +getCondoId(),
      month: moment.utc(new Date()).format(),
      year: moment.utc(new Date()).format('YYYY'),
      due_date: moment.utc().add(1, 'day').format('YYYY-MM-DD'),
      send_email: true,
      send_whatsapp: false
    }
  )

  const storageDate = getFilterDate()
  const { enqueueSnackbar } = useSnackbar()

  const [summary, setSummary] = useState(true)
  const [openForm, setOpenForm] = useState(false)
  const [isPaid, setIsPaid] = useState(false)
  const [errors, setErrors] = useState(null)
  const [month, setMonth] = useState(moment(new Date(storageDate)).format("MM"))
  const [year, setYear] = useState(moment(new Date(storageDate)).format("YYYY"))
  const { loadingModal, filters, setFilters } = useContext(Context)

  const monthFormat = +moment(storageDate).format("MM")
  const yearFormat = +moment(storageDate).format("YYYY")

  function handleStatusFilter (e, value){
    if (value !== 'all') {
      setFilters({ ...filters, status: value })
    } else {
      delete filters?.status
      setFilters({ ...filters })
    }
  }

  function handleManualPayment (){
    setIsPaid(true)
  }

  const handleCreateSlip = () => {
    const data = {
      ...chargeReq,
      month: +moment.utc(chargeReq?.month).format('MM'),
      year: +chargeReq?.year
    }

    api.post('bank-slip/billing-month/', data)
      .then(response => {
        if (response?.status === 201) {
          enqueueSnackbar(response?.data.success, { variant: 'success' })
          setOpenForm(false)
        }
    })
      .catch(response => {
        setErrors(response?.response.data)
    })
  }


  useEffect(function invoiceSummary(){
    const condo = getCondoId()
    if(response) {
    const summaryData = {
      condo: +condo,
      start_month: month,
      end_month: month,
      start_year: year,
      end_year: year,
    }

    api.post(`analytics/invoice-summary/`, summaryData)
        .then(response => {
          const data = response.data
          setSummary(data)
        }).catch(error => {
    })
    }

  }, [response])

  useEffect(()=>{
    const date = storageDate ? moment(new Date(storageDate)).format() : moment().format()
    setFilterDate(date)
    if (getCondoType() === "CONDO"){
      console.log(getCondoType())
      setFilters(prevState =>({
        ...prevState,
        unit__condo_id: getCondoId(),
        page_size: 50,
        billing_month__month: monthFormat,
        billing_month__year: yearFormat
      }))
    }else{

      setFilters({
        unit__condo_id: getCondoId(),
        page_size: 50,
        month: monthFormat,
        year: yearFormat
      })
    }

  },[])


  function getInvoices() {
    console.log(filters)
    const params = {
      month,
      year,
      unit__condo_id: getCondoId()
    }
    api.get(`invoice/`, {params})
      .then(response => {
        const data = response.data
        setResponse(data)
      }).catch(error => {
      console.log(error)
    })
  }
  useEffect(()=>{
    getInvoices()
  }, [])

  useEffect( ()=> {
      getInvoices()
  },[month, year, isPaid, loadingModal])


  return (
    <>
      <Helmet>
        <title>Faturas - Bloco B3</title>
      </Helmet>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1500, }}
        open={loadingModal}
      >

        <CircularProgress color="inherit"/>
      </Backdrop>
      <Container maxWidth={false}>
        <Box
          sx={{
            display: 'flex',
            '@media (max-width:600px)': {
              display: 'grid',
              gridTemplateColumns: 'repeat(1, 1fr)',
              gap: 3,
            },
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,

          }}
        >
          <Typography
            textAlign={'center'}
            variant={'h5'}
          >
            Faturas
          </Typography>

            <Box
              sx={{
                '@media (max-width:600px)': {
                  display: 'grid',
                  gridTemplateColumns: 'repeat(1, 1fr)',
                  gap: 2,

                }
              }}
            >


                <Button
                  variant={'contained'}
                  href="/faturas/cobranca"
                  size={"small"}
                  sx={{
                    ml: '1rem',
                    '@media (max-width:600px)': {
                      ml: 0,
                    }
                  }}
                >
                  Gerar Cobrança Individual
                </Button>

            </Box>

        </Box>

          <>
            {/*<CardInvoiceInfo date={storageDate}/>*/}
          </>
          <Card>
            {response?.results ?
              <>

            <Grid container sx={{ p: 2, alignItems: 'center', justifyContent:"end" }} spacing={1}>
              {/*<Grid item xs={12} sm={4} lg={3}>*/}
              {/*  <UnitFilter />*/}
              {/*</Grid>*/}
              <Grid item xs={12} sm={6} lg={6} sx={{display: "flex", justifyContent: "start"}}>
                <StatusTabFilter
                  onChange={handleStatusFilter}
                  summary={summary}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6} sx={{textAlign: "right"}}>
                <DatePicker
                  openTo="month"
                  views={['year', 'month']}
                  label="Mês de Pagamento"
                  inputProps={{ readOnly: true }}
                  disableMaskedInput
                  value={moment(`${month}-${year}`, 'MM-YYYY').format()}
                  onChange={(newValue) => {
                    setMonth(moment(new Date(newValue)).format("MM"))
                    setYear(moment(new Date(newValue)).format("YYYY"))
                    console.log(newValue)
                    setFilterDate(moment(newValue, 'MM-YYYY').toISOString())
                  }}
                  renderInput={(params) =>
                    <TextField

                      {...params}
                    />}
                />
              </Grid>
            </Grid>
          <Divider/>
            <TableContainer>
              {response?.results?.length > 0  ?
                <Table>
                  <TableInvoiceHead />
                  <TableInvoiceBody
                    state={response}
                    handleManualPayment={handleManualPayment}
                  />
                </Table>
                :
            <NoResultsAnimation />
             }
          </TableContainer>
              </>
              :
          <Skeleton
            variant="rounded"
            height={700}
            animation="pulse"
          />
            }
          </Card>
          <ModalForm
            id="model-invoice-form"
            keepMounted
            title={'Gerar Cobrança'}
            open={openForm}
            onClose={() => setOpenForm(false)}
            errors={errors}
            callback={handleCreateSlip}
          >
          <GenerateCharge
            chargeReq={chargeReq}
            setChargeReq={setChargeReq}
            errors={errors}
            setErrors={setErrors}
          />

          </ModalForm>
      </Container>
      {response?.count > 0 &&
        <PaginationGlobal
          state={response}
          filters={filters}
          setFilters={setFilters}
        />
      }
    </>
  )
}

export default InvoiceList
